<template>
  <eagle-form :form-key="formKey"></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerFormConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'provider-form',
    meta: {},
  }),
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async readApi(target) {
      const res = await this.$api.collection.providerApi.read(target)
      res.welcome_text = res.welcome_text ? res.welcome_text  : '歡迎歡迎歡迎歡迎歡迎歡迎歡迎歡迎'
      res.contact = res.contact ? res.contact  : '聯繫方式聯繫方式聯繫方式'
      return res
    },
    async createApi(formData) {
      return await this.$api.collection.providerApi.create(formData)
    },
    async updateApi(target, formData) {
      return await this.$api.collection.providerApi.update(target, formData)
    },
    async deleteApi(target) {
      return await this.$api.collection.providerApi.delete(target)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
