import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import bankList from "@/config/bank.json"
import citiesList from "@/config/citiesList.json"

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      targetKey: 'provider_id',
      from: () => [
        { label: 'page.provider', route: { name: 'provider' } },
      ],
      listRoute: () => ({ name: 'provider' }),
      afterCreateRoute: result => ({
        name: 'provider-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.provider',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            name: {
              required: true,
              label: 'provider.data.name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            uniform_number: {
              label: 'data.uniform_number',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_name: {
              label: 'data.manager-name',
              type: 'text',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_phone: {
              label: 'provider.data.member_phone',
              type: 'text',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            email: {
              label: 'provider.data.email',
              type: 'text',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            phone: {
              label: 'provider.data.phone',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            city: {
              label: 'provider.data.city',
              type: 'selection',
              clearable: true,
              options: () => {
                return citiesList.map(item => ({
                  text: item.city,
                  value: item.city
                }))
              },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            district: {
              label: 'provider.data.district',
              type: 'selection',
              clearable: true,
              options: (formInfo) => {
                if(!formInfo.formData) return []
                const city = formInfo.formData.city
                if(!city) return []

                const targetCity = citiesList.find(item => {
                  return item.city == city
                })

                if(!targetCity) return []

                return targetCity.districts.map(district => ({
                  text: district.name,
                  value: district.name
                }))
              },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            address: {
              label: 'data.deliver-address',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            business_name: {
              label: 'data.business_name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        {
          title: 'provider.form_block.payment_info',
          titleSlot: () => import('@/modules/provider/component/codeValidator.vue'),
          data: {
            payment_type: {
              label: 'provider.data.payment_type',
              required: true,
              type: 'selection',
              multiple: true,
              options: (formInfo) => {
                // @ts-ignore
                return vm.$paymentTypes.map(type => ({text: vm.$t(`provider.data.payment_type.${type}`), value: type}))
              },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            bank_account_name: {
              label: 'provider.data.bank_account_name',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            bank_account: {
              label: 'provider.data.bank_account',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            bank_info: {
              label: 'provider.data.bank_info',
              type: 'selection',
              clearable: true,
              options: (formInfo) => bankList.map(bank => ({
                text: `(${bank.id})${bank.name}`,
                value: `(${bank.id})${bank.name}`,
              })),
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            bank_branch_id: {
              label: 'provider.data.bank_branch_id',
              type: 'text',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },

          },
        },

        {
          title: 'provider.form_block.member_info',
          data: {
            members: {
              component: ()=>import('modules/member/views/providerMemberList.vue'),
              grid: {
                xl: 12,
                lg: 12,
                md: 12,
                sm:12,
              },
            },
          },
        },

        {
          title: 'provider.form_block.line_info',
          data: {
            line_at_id: {
              label: 'provider.data.line_at_id',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            line_liff_id: {
              label: 'provider.data.line_liff_id',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            line_login_channel_id: {
              label: 'provider.data.line_login_channel_id',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            line_login_channel_secret: {
              label: 'provider.data.line_login_channel_secret',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            line_message_channel_secret: {
              label: 'provider.data.line_message_channel_secret',
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            line_message_channel_access_token: {
              label: 'provider.data.line_message_channel_access_token',
              maxlength: 200,
              required: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            welcome_text: {
              label: 'provider.data.welcome_text',
              type: 'textarea',
              required: true,
              grid: {
                md: 12,
                lg: 12,
                xl: 12
              },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            contact: {
              label: 'provider.data.contact',
              type: 'textarea',
              required: true,
              grid: {
                md: 12,
                lg: 12,
                xl: 12
              },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
      dataAction: {
        loginAsProvider: true
      },
    }
  }

}

export default new formConfig()
